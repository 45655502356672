import React from "react";

export interface AuthenticationContextProps {
  sessionToken: string | null;
  authenticatedWithGoogle: boolean;
  resetSession: () => void;
  setSessionToken: (token: string | null) => void;
  setAuthenticatedWithGoogle: (flag: boolean) => void;
}

export const AuthenticationContext = React.createContext<AuthenticationContextProps>({
  sessionToken: null,
  authenticatedWithGoogle: false,
  resetSession: () => { },
  setSessionToken: () => { },
  setAuthenticatedWithGoogle: (flag: boolean) => { }
});
