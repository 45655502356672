import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import { AuthenticationContext } from "contexts/AuthenticationContext";

import { Paths } from "types/Paths";

interface PrivateRouteProps {
  shouldBeSignedIn: boolean;
  children: React.ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  shouldBeSignedIn,
  children
}) => {
  const { sessionToken } = useContext(AuthenticationContext);

  if (shouldBeSignedIn && !sessionToken) {
    return <Navigate to={Paths.Login} />;
  }
  else if (!shouldBeSignedIn && sessionToken) {
    return <Navigate to={Paths.Home} />;
  }
  else {
    return <>{children}</>;
  }
}

export default PrivateRoute;
