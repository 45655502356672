import tailwindConfig from "tailwind.config";
import DefaultColors from "tailwindcss/colors";
import DefaultConfig from "tailwindcss/defaultConfig";
import DefaultTheme from "tailwindcss/defaultTheme";
import resolveConfig from "tailwindcss/resolveConfig";
import { Config } from "tailwindcss/types/config";

export const config = resolveConfig((tailwindConfig as any) as Config) || DefaultConfig;

export const theme = config.theme || DefaultTheme;

export const colors = JSON.parse(JSON.stringify(theme.colors || DefaultColors));

export const clx = (...classes: (string | undefined | null)[]) => classes.join(" ");
