/* eslint-disable react/prop-types */
import React from "react";

const WelcomeBanner: React.FC<{ title?: string, subtitle?: string }> = ({ title, subtitle }) => {
  return (
    <div className="relative bg-indigo-200 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">

      {/* Content */}
      <div className="relative">
        <h1 className="text-2xl md:text-3xl text-slate-800 font-medium mb-1">{title}</h1>
        <p>{subtitle}</p>
      </div>

    </div>
  );
}

export default WelcomeBanner;
