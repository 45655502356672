import { FieldValues, FieldPath, FieldErrors, FieldError } from "react-hook-form";

interface FormFieldProps<T extends FieldValues, TName extends FieldPath<T>> {
  formName?: string;
  name: TName;
  label: string;
  errors?: FieldErrors<T>;
  required?: boolean;
  className?: string;
  render: (name: TName, id: string, label: string) => React.ReactNode;
}

const FormField = <T extends FieldValues, TName extends FieldPath<T>>(
  props: FormFieldProps<T, TName>
) => {
  const {
    formName, name, label, errors, required,
    className,
    render
  } = props;

  const id = `${formName || "form"}-${name}`;
  const error = errors? errors[name] as FieldError : undefined;

  return (
    <div className={className}>
      <label
        className="block mb-2 text-sm font-medium text-primary-500"
        htmlFor={id}
      >{label}{required && " *"}</label>
      {render(name, id, label)}
      {error &&
      <div className="text-sm text-red-600 font-medium mt-2">
        {error.message}
      </div>}
    </div>
  );
}

export default FormField;
