/* eslint-disable react/prop-types */
import React, { useState } from "react";

import UserMenu from "../components/DropdownProfile";

function Header() {
  const [title, setTitle] = useState("")

  var target = document.querySelector("title");
  var observer = new MutationObserver(function(mutations) {
    setTitle(mutations[0].target.textContent)
  });
  var config = { subtree: true, characterData: true, childList: true };
  observer.observe(target, config);

  return (
    <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">

          {/* Header: Left side */}
          <div className="flex">
            <h1 className="text-2xl md:text-3xl text-slate-800 font-medium">{title}</h1>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center space-x-3">
            {/*  Divider */}
            <hr className="w-px h-6 bg-slate-200 mx-3" />
            <UserMenu align="right" />

          </div>

        </div>
      </div>
    </header>
  );
}

export default Header;
