import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { APIError, callAPI } from "helpers/api";

import { Session } from "types/API";

export interface SignInRequest {
  email: string;
  password: string;
}

export interface SignInResponse {
  session: Session;
}

export const useSignIn = (
  options?: UseMutationOptions<SignInResponse, APIError, SignInRequest>
) => {
  return useMutation({
    ...options,
    mutationFn: (request) => callAPI("post", "/api/v1/session", request),
  });
}
