import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import ErrorToast from "components/ErrorToast";
import Toast from "components/Toast";
import { ToastLevel } from "components/Toast/Toast";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { useSignIn } from "services/signIn";

import { Paths } from "types/Paths";

import LoginForm, { LoginFormValues } from "./LoginForm";

import logo from "assets/logo.svg";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setSessionToken } = useContext(AuthenticationContext);

  const { mutate, isLoading, error } = useSignIn({
    onSuccess: (data) => {
      setSessionToken(data.session.token);
      navigate(Paths.Home);
    }
  });

  const handleSubmit = (values: LoginFormValues) => {
    mutate(values);
  }

  const autoSignout = searchParams.get("autoSignout") !== null;

  return (
    <div className="flex flex-col items-center justify-center h-screen p-6 mx-auto">
      <div className="w-full bg-white rounded-lg shadow sm:max-w-md">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <div className="container flex justify-center mb-8">
            <img
              src={logo}
              alt="logo"
              className="w-3/4" />
          </div>
          <p
            className="text-primary-400 font-medium">
            Please enter your provider portal credentails.
          </p>
          <LoginForm onSubmit={handleSubmit} disabled={isLoading} />
          {error && <ErrorToast error={error} />}
          {autoSignout &&
          <Toast
            level={ToastLevel.WARNING}
            message="You were automatically signed out, please sign in again."
          />}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
