import { useState } from "react";

export function useLocalStorage(
  key: string, initialValue: string | null = null
): [string | null, (value: string | null) => void] {
  const [item, setItem] = useState(() => {
    const value = window.localStorage.getItem(key);
    return value !== null ? value : initialValue;
  });

  const updateItem = (value: string | null) => {
    setItem(value);
    if (value === null) {
      window.localStorage.removeItem(key);
    }
    else {
      window.localStorage.setItem(key, value);
    }
  };

  return [item, updateItem];
}
