import * as Toast from "@radix-ui/react-toast";
import * as Tooltip from "@radix-ui/react-tooltip";
import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import PrivateRoute from "components/PrivateRoute";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { SESSION_TOKEN_KEY, AUTHENTICATED_WITH_GOOGLE_KEY } from "helpers/api";
import { useLocalStorage } from "hooks/useLocalStorage";
import AuthenticationLayout from "layouts/Authentication/AuthenticationLayout";
import DashboardLayout from "layouts/Dashboard/DashboardLayout";
import Home from "pages/Home";
import LoginPage from "pages/Login/LoginPage";

import { Paths } from "types/Paths";

const App: React.FC = () => {
  const queryClient = new QueryClient();

  const [sessionToken, setSessionToken] = useLocalStorage(SESSION_TOKEN_KEY);
  const [
    authenticatedWithGoogleBoxed,
    setAuthenticatedWithGoogleBoxed] = useLocalStorage(AUTHENTICATED_WITH_GOOGLE_KEY);

  const authenticatedWithGoogle = authenticatedWithGoogleBoxed === "true"
  const setAuthenticatedWithGoogle = (unboxedType: boolean) => {
    setAuthenticatedWithGoogleBoxed(String(unboxedType))
  }

  const resetSession = () => {
    setSessionToken(null)
    setAuthenticatedWithGoogle(false)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticationContext.Provider value={{
        sessionToken,
        authenticatedWithGoogle,
        resetSession,
        setSessionToken,
        setAuthenticatedWithGoogle
      }}>
        <Tooltip.Provider>
          <Toast.Provider swipeDirection="up">
            <Toast.Viewport />
            <BrowserRouter>
              <Routes>
                <Route
                  path={Paths.Root}
                  element={sessionToken ?
                    <Navigate to={Paths.Home} replace /> :
                    <Navigate to={Paths.Login} replace />
                  }
                />
                <Route element={<AuthenticationLayout />}>
                    <Route path={Paths.Login} element={
                      <PrivateRoute shouldBeSignedIn={false}>
                        <LoginPage />
                      </PrivateRoute>
                    } />
                </Route>
                <Route element={<DashboardLayout />}>
                    <Route path={Paths.Home} element={
                      <PrivateRoute shouldBeSignedIn>
                        <Home />
                      </PrivateRoute>
                    } />
                </Route>
              </Routes>
            </BrowserRouter>
          </Toast.Provider>
        </Tooltip.Provider>
      </AuthenticationContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
