import React from "react";
import { useForm } from "react-hook-form";

import FormField from "components/FormField";
import { ErrorMessages } from "helpers/messages";
import { clx } from "helpers/tailwind";
import { emailPattern, passwordPattern } from "helpers/validation";

export interface LoginFormValues {
  email: string;
  password: string;
}

interface LoginFormProps {
  disabled?: boolean;
  initialValues?: LoginFormValues;
  onSubmit: (values: LoginFormValues) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  disabled, initialValues, onSubmit,
}) => {
  const defaultValues = initialValues || {
    email: "",
    password: "",
  };

  const { register, handleSubmit, formState: { dirtyFields, errors } } = useForm<LoginFormValues>({
    defaultValues
  });

  return (
    <form
      className="space-y-4 md:space-y-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormField
        name="email"
        label="Email"
        errors={errors}
        required
        render={(name, id) =>
          <input
            {...register(name, {
              required: ErrorMessages.REQUIRED,
              pattern: {
                value: emailPattern,
                message: ErrorMessages.EMAIL_FORMAT
              }
            })}
            id={id}
            type="text"
            disabled={disabled}
            placeholder="email@example.com"
            className={clx(
              "rounded-lg block w-full p-2.5 border border-primary-200",
              "text-primary-800 text-sm font-medium placeholder:text-primary-200",
              "focus:outline-none focus:ring-2 focus:ring-primary-300 focus:border-primary-400"
            )}
          />
        }
      />
      <FormField
        name="password"
        label="Password"
        errors={errors}
        required
        render={(name, id) =>
          <input
            {...register(name, {
              required: ErrorMessages.REQUIRED,
              pattern: {
                value: passwordPattern,
                message: "Password has to be at least 6 symbols long."
              }
            })}
            id={id}
            type="password"
            disabled={disabled}
            className={clx(
              "rounded-lg block w-full p-2.5 border border-primary-200",
              (dirtyFields.password ? "text-primary-800" : "text-primary-200"),
              "text-sm font-medium placeholder:text-primary-200 focus:text-primary-800",
              "focus:outline-none focus:ring-2 focus:ring-primary-300 focus:border-primary-400",
            )}
          />
        }
      />
      <button
        type="submit"
        disabled={disabled}
        className={clx(
          "w-full text-white bg-primary-600 font-medium rounded-lg px-5 py-2.5 text-center",
          "hover:bg-primary-400 active:bg-primary-700",
          "focus:outline-none focus:ring-2 focus:ring-primary-300",
        )} >
        Sign in
      </button>
    </form>
  );
}

export default LoginForm;
