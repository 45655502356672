import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/solid";
import * as ToastPrimitive from "@radix-ui/react-toast";
import React from "react";

import { clx } from "helpers/tailwind";
import { hash } from "helpers/text";

export enum ToastLevel {
  // eslint-disable-next-line no-unused-vars
  TIP,
  // eslint-disable-next-line no-unused-vars
  SUCCESS,
  // eslint-disable-next-line no-unused-vars
  WARNING,
  // eslint-disable-next-line no-unused-vars
  ERROR
}

interface ToastProps extends ToastPrimitive.ToastProps {
  level?: ToastLevel;
  title?: string;
  message: string | string[];
}

const Toast: React.FC<ToastProps> = ({
  level, title, message, ...props
}) => {
  var bodyColor = "text-primary-500";
  var closeIconColor = "text-primary-200";
  var titleLabel = (
    <span className="text-primary-300">
      <InformationCircleIcon className="inline w-4 align-top" /> {title || "Tip"}
    </span>
  );

  switch (level) {
    case ToastLevel.WARNING:
      bodyColor = "text-accent-500";
      closeIconColor = "text-accent-300";
      titleLabel = (
        <span className="text-accent-400">
          <ExclamationTriangleIcon className="inline w-4 align-top" /> {title || "Warning"}
        </span>
      );
      break;
    case ToastLevel.ERROR:
      bodyColor = "text-red-600";
      closeIconColor = "text-red-300";
      titleLabel = (
        <span className="text-red-400">
          <ExclamationCircleIcon className="inline w-4 align-top" /> {title || "Error"}
        </span>
      );
      break;
    case ToastLevel.SUCCESS:
      bodyColor = "text-mint-600";
      closeIconColor = "text-mint-400";
      titleLabel = (
        <span className="text-mint-500">
          <CheckCircleIcon className="inline w-4 align-top" /> {title || "OK"}
        </span>
      );
      break;
  }

  return (
    <ToastPrimitive.Root
      {...props}
      className={clx(
        "fixed top-4 bg-white rounded-lg shadow-lg z-50 p-4",
        "w-[calc(100%-2rem)] left-4 sm:w-[calc(100%-12rem)] sm:left-24 md:w-1/2 md:left-1/4",
        "xl:left-1/3 xl:w-1/3",
        "radix-state-open:animate-toast-slide-in-top animate-fade-in",
        "radix-state-closed:animate-toast-slide-out-top animate-fade-out",
        "flex flex-row"
      )}
    >
      <div className="flex-grow flex flex-col">
        <ToastPrimitive.ToastTitle className="text-xs uppercase font-medium">
          {titleLabel}
        </ToastPrimitive.ToastTitle>
        <ToastPrimitive.Description className={`mt-1 font-medium ${bodyColor}`}>
          {[message].flat().map(message => <div key={hash(message)}>{message}</div>)}
        </ToastPrimitive.Description>
      </div>
      <ToastPrimitive.Close aria-label="Close">
        <XCircleIcon className={`w-7 ${closeIconColor}`} />
      </ToastPrimitive.Close>
    </ToastPrimitive.Root>
  );
};

export default Toast;
