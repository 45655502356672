import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./styles/base.scss";

const sentryDSN = (window as any).SENTRY_WEB_DSN;
// eslint-disable-next-line
if (sentryDSN && sentryDSN !== '${SENTRY_WEB_DSN}') {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // Known issue caused by Rails ActionCable.
      "Cannot read property 'received' of undefined",
      // Authentication errors, we don't need to report those.
      "Request failed with status code 401"
    ]
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
