import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import WelcomeBanner from "../libs/mosaic/partials/home/WelcomeBanner";

function Home() {

  const location = useLocation();

  useEffect(() => {
    const html = document.querySelector("html")
    if (html) {
      html.style.scrollBehavior = "auto"
      window.scroll({ top: 0 })
      html.style.scrollBehavior = ""
    }
  }, [location.pathname]); // triggered on route change

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

      {/* Welcome banner */}
      <WelcomeBanner title="Good afternoon, Empower Sleep. 👋"/>


      {/* Dashboard actions */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">

      </div>
    </div>
  );
}

export default Home;
