import axios, { AxiosError } from "axios";
import applyCaseMiddleware from "axios-case-converter";

import { Paths } from "types/Paths";

export const SESSION_TOKEN_KEY = "sessionToken";
export const AUTHENTICATED_WITH_GOOGLE_KEY = "authenticated_with_google";

export interface ErrorResponse {
  errors: string[];
}

export type APIError = AxiosError<ErrorResponse> | Error;

export const getAuthenticityToken = () => {
  const authenticityToken = document.querySelector("meta[name=\"authenticityToken\"]") as HTMLMetaElement;
  if (authenticityToken) {
    return authenticityToken.content;
  }
  else {
    return "";
  }
}

const axiosClient = applyCaseMiddleware(axios.create());

export async function callAPI<TResponse, TRequest = any>(
  method: "get" | "post" | "put" | "delete",
  url: string,
  request?: TRequest
) {
  const isAuthentication = (method === "post" && url.endsWith("/session"));

  const headers = {
    "X-CSRF-Token": getAuthenticityToken()
  }

  try {
    const response = await axiosClient<TResponse>({
      method: method,
      url: url,
      params: request,
      headers: !isAuthentication ? {
        ...headers,
        "Authorization": window.localStorage.getItem(SESSION_TOKEN_KEY)
      } : headers
    });
    return response.data;
  }
  catch (error) {
    // TODO: Add logging here based on the severity of the error.
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ErrorResponse>;
      if (!isAuthentication && axiosError.response?.status === 401) {
        // Auto signout on 401.
        window.localStorage.removeItem(SESSION_TOKEN_KEY);
        window.localStorage.removeItem(AUTHENTICATED_WITH_GOOGLE_KEY);
        setTimeout(() => {
          window.location.href = Paths.Login + "?autoSignout=1";
        }, 100);
      }
      throw axiosError;
    }
    else {
      const otherError = error as Error;
      throw otherError;
    }
  }
}
