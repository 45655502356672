import { AxiosError } from "axios";
import React from "react";

import Toast from "components/Toast";
import { ToastLevel } from "components/Toast/Toast";
import { ErrorResponse } from "helpers/api";

interface ErrorToastProps {
  error: Error | AxiosError<ErrorResponse>;
}

const ErrorToast: React.FC<ErrorToastProps> = ({
  error
}) => {
  var messages = ["Unknown error has occured, please refresh and try again."];
  var level = ToastLevel.ERROR;

  if (error instanceof AxiosError) {
    const axiosError = error as AxiosError<ErrorResponse>;
    const errors = axiosError.response?.data.errors;
    if (errors !== undefined && errors.length > 0) {
      messages = errors;
      level = ToastLevel.WARNING;
    }
  }

  return (
    <Toast level={level} message={messages} />
  );
}

export default ErrorToast;
